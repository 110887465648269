.leftPanel {
    padding: 20px;
}

.imageWrapLeft {
    float: right;
    margin-left: 20px;
    margin-top: 5px;
    border: 1px solid #000;
    width: 32%;
    max-width: 45%;
}

.imageWrapRight {
    float: left;
    margin-right: 20px;
    margin-top: 5px;
    border: 1px solid #000;
    width: 32%;
    max-width: 45%;
}

.imageWrapMiddle {
    float: left;
    margin-top: 5px;
    border: 1px solid #000;
    width: 32%;
    max-width: 45%;
}

.containerDim {
    width: 100%;
}

.containerDim2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.containerHeight {
    height: 5px;
    width: 100%;
}

.containerHeight2 {
    height: 15px;
    width: 100%;
}