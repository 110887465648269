.app-container {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr minmax(0, 800px) 1fr;
    grid-template-areas: 
    'header'
    'main'
    'footer';
    background-image: url('./images/sun-tornado-light.svg');
    background-size: cover;
}

.grid-header {
    grid-area: header;
}

.grid-main {
    grid-area: main;
    overflow: auto;
}

.grid-footer {
    grid-area: footer;
}