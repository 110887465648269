.leftPanel {
    padding: 20px;
}

.socialIcon {
    filter: drop-shadow(0px 0px 5px rgb(112, 112, 112));
    transition: .7s;
}
  
.socialIcon:hover {
    transform: rotate( -20deg ) scale( 1.5 );
}

.mailPlacement {
    text-align: center;
    display: block;
}