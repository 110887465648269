.main-container {
    display: grid;
    margin: 20px 30px 50px 30px;
}

.contentModule {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}