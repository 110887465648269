.nav-elevation {
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
    width: 100%;
    height: 100px;
    z-index: 10;
    background-image: url('../images/animated-header.svg');
    background-size: cover;
}

.nav-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.nav-left {
    float: left;
    padding-left: 240px;
    padding-top: 8px;
}

.nav-right {
    float: right;
    padding-right: 30px;
    padding-top: 34px;
    text-transform: uppercase;
}